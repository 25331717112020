<template>
    <div id="about">
        <PageTitle :title="title" />
        <!-- <div class="about-container" v-if="false">
            <div class='youtube-embed-container'>
                <iframe :src="'https://www.youtube.com/embed/'+ 'jNqRrC9YFmw'" frameborder='0' allowfullscreen></iframe>
            </div>
        </div> -->
        <div class="intro-container">
            <p class="read-more-short">
                With over $750 million in total real estate sales and 19 years of experience, Paul Salazar is a seasoned real estate professional known for his deep market knowledge, strategic insight, and ability to deliver results in Los Angeles’ most competitive neighborhoods. From Beverly Hills and Bel Air to Santa Monica, Brentwood, Pacific Palisades, Venice, and Mar Vista, Paul has helped hundreds of clients navigate the complexities of buying, selling, and developing luxury properties.
                <br><br>
                As the Estates Director at Hilton & Hyland, one of the world’s top luxury brokerages, Paul specializes in rebranding and relaunching properties that have lingered on the market and works closely with developers to bring luxury spec homes to life across LA. His sharp eye for presentation, strong negotiation skills, and commitment to client satisfaction have made him a trusted name in the industry.
                <br><br>            
            </p>
            <p v-if="readMoreOpen" class="read-more-long">
                Paul is also deeply passionate about personal growth and men’s wellness. He is the host of “On Frequency”, a podcast where he explores themes of spirituality, mindset, and personal evolution through authentic conversations with inspiring individuals. In Los Angeles, he leads a men’s group focused on spiritual and emotional development, and regularly hosts live community events centered around self-discovery, connection, and meaningful dialogue.
                <br><br>
                Outside of work, Paul is an adventurer with a love for travel, sailing, and watersports. His adventurous spirit, shaped by his Semester at Sea experience and NCAA Division 1 soccer background, continues to fuel his curiosity and drive.
                <br><br>
                One of the keys to Paul's success is his commitment and desire to meet and exceed his clients’ needs. Paul's focus on building relationships, providing comprehensive and personalized customer service, and using technology to facilitate the transaction process has provided him with a competitive edge in the business.
                <br><br>
                To strategically take advantage of today's real estate market or find your dream home, consult with Paul Salazar before your next move!
                <br><br>
            </p>
            <div class="read-more-button" @click="toggleReadmore">
                <font-awesome-icon :icon="['fas', `${readMoreOpen ? 'chevron-up' : 'chevron-down'}`]" class="icon"/>
            </div>
        </div>
        <InfoBlock :data="info3" id="testimonial"/>
        <Testimonial :data="testimonial" class="testimonial"/>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import InfoBlock from "../components/InfoBlock"
import Testimonial from "../components/Testimonial"

export default {
    name: "About",
    components: {
        PageTitle,
        InfoBlock,
        Testimonial,
    },
    data(){
        return{
            title: "About Paul Salazar",
            readMoreOpen: false,
            window: {
                width: 0,
                height: 0
            },
            info3:{
                number: '75+',
                unit: '5-Star<br>Reviews',
                background: '/images/paul-salazar-about-003.jpg',
                smallText: true,
            },
            testimonial: [
                {
                    title: '709 N Bedford Dr',
                    content: `We just want to send our compliments to realtor, Paul Salazar of Hilton & Hyland for the great job he did in selling our family home in Beverly Hills after the death of our father.   Just the thought of going through all of our family belongings that had accumulated after 40 years and trying to figure out what to do with it all was overwhelming as well as emotional. Our family now lives on the east coast, and although, we never met Paul in person, we chatted over the phone many times. He was so friendly, professional and compassionate.  We immediately took a liking to him and knew he was the one we wanted to sell our family’s house. He understood that these were unusual times and that there were still items in the house that either needed to be given away or discarded. He also understood that we could not travel to Los Angeles because of the pandemic. We needed to review everything online and  sign documents that way as well. Paul jumped right in and explained his plan to sell the house. He was able to contact the right agencies to clean out most of the house except for some basic furniture. Paul supervised everything for us, including donation of a vehicle. We were very impressed that Paul was able to get many offers before the house was even listed in the MLS, and the house sold within 30 days. Paul was technologically savvy and helped us to prioritize all the offers on a spread sheet with the major points listed.  He went above and beyond what any other realtor would have done for us. Our family wants to say thank you to Paul Salazar and Hilton & Hyland for the excellent job and service that was provided.`,
                    author: ' - Pam'
                },
                {
                    title: '644 S Rimpau Blvd',
                    content: `We worked with Paul Salazar over three years to find our dream home. We gave him a list of historic homes in Los Angeles that we were interested in, and Paul tracked down the owners, sometimes going door-to-door to see if they were interested in selling. Throughout this, he stayed positive, encouraging us to not settle for anything less than the best. For every house, he researched comps for the area, giving us an idea of what each home was worth at the time and would be down the road.  In every case, he was spot on, understanding that although this was our dream house, it was also an investment. He never pushed us into a quick sale as we saw so many other realtors do.In every case, he became an expert of the neighborhood—its past and its potential—and taught us all about short sales, HPOZ guidelines, Mills Act, Historic Cultural Monuments, and Easement Laws. Paul ended up finding our dream home that was off-market by combing through EVERY SINGLE historic home that was for rent in Los Angeles. By being persistently in touch with the agent, Paul got the owner to agree to sell us the house. During the offer process, Paul was our coach and our therapist, fielding calls from us multiple times a day. After the owner accepted our offer, Paul then found us an incredible lender, who gave us the best rates that the escrow agent had ever seen. Paul didn’t end there—he and his team were extremely hands on during the inspection process, and he successfully negotiated $170,000 for repairs. We have owned our home for less than a month, and Zillow already lists it for $700,000 more than we bought it for. Our family could not be happier with Paul Salazar and his team—they really have become a part of our family.`,
                    author: ' - Helen & Tobin'
                },
                {
                    title: '7825 Torreyson Dr',
                    content: `Paul took charge and successfully sold a completely renovated property in the Hollywood Hills which we had been trying to sell for over three years. He conceptualized a marketing plan, worked with us to insure that the property sold and took care off all of the small details that contribute to a successful sale. This was an investor owned property and Paul insured that there were service people available for cleaning, minor repairs and the like. He negotiated extensively with the buyer and concluded a sale that had eluded us for a long time. His service was outstanding, and we will use him for other properties that we develop.`,
                    author: ' - Stewart'
                },
                {
                    title: '2401 Jupiter Dr',
                    content: `Before Paul took over, I had my house listed with another agent who didn’t sell the property. Paul and his team came in and made a few small changes, such as painting the blue baseboards and master bedrooms walls, which ended up transforming the home for the new potential buyers. We received multiple offers within the first few days and he sold it for full asking price. While we were in escrow, Paul still conducted showings in effort to get back-up offers. Throughout the transaction, Paul’s team was very helpful and easy to communicate with even though I was living on the east coast. I am very pleased with my experience working with the Paul Salazar Group. `,
                    author: ' - Mann'
                },
                {
                    title: '1310 Preston Way',
                    content: `I am writing to thank Paul and his team for assisting us in selling our trust property. I have been an off and on resident of the property for about 65 years, until my father’s passing when I was made trustee. I needed a Realtor with skills to negotiate legal assistance in the sale of the trust property. I required a team that was friendly, knowledgeable and possessed good communication skills. The property involved an easement to an adjoining neighbor property and some additional modifications for bringing my property up to current codes. This coordination was done seamlessly from my perspective through fair negotiations with all parties, whether buyer and seller or neighbor and neighbor. Being a first-time seller, my questions were quickly and adequately answered in a manner that I could understand and the next step was described so I could sleep well and rest. I would highly recommend Paul and Maggie and the team to any prospective buyer or seller.`,
                    author: ' - Sue & Fred'
                },
            ]
        }
    },
    metaInfo: {
        title: 'About',
        titleTemplate: '%s | Paul Salazar',
        meta: [{
            name: 'description',
            content: "Paul Salazar"
        }]
    },
    methods:{
        toggleReadmore: function(){
            this.readMoreOpen = !this.readMoreOpen;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
    computed:{
        slideToShow: function(){
            if(this.window.width > 980){
                return 3;
            } else if (this.window.width > 600){
                return 2;
            } else {
                return 1;
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss" scoped>
.youtube-embed-container{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.youtube-embed-container iframe,
.youtube-embed-container object,
.youtube-embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.videos-container{
    margin: 2rem 4rem;
}

.about-container{
    margin: 4rem 4rem;
}

.info{
    padding: 4rem 20%;
    background: #1a1a1a;
    text-align: center;
    a{
        text-transform: uppercase;
        opacity: 0.5;
        font-size: 20px;
        display: block;
        margin: 1.5rem;
    }
}
.testimonial{
    margin-bottom: 10vh;
}

.icon{
    color: #fff;
    opacity: 0.6;
    &:hover{
        cursor: pointer;
        opacity: 1;
    }
}

.intro-container{
    padding: 4rem 20%;
    p{
        margin: 0 auto;
        max-width: 1000px;
    }
    .read-more-button{
        margin: 0 auto;
        text-align: center;
    }
}

.team-container{
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 4rem 0;
    flex-wrap: wrap;
    .team-member-container{
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 80%;
            max-width: 180px;
        }
        h3{
            opacity: 0.5;
            margin: 0;
            margin-top: 20px;
            color: #fff;
        }
        p{
            margin:0;
        }
    }
}

@media (max-width: $mobile) { 
    .about-container{
        margin: 0;
        margin: 2rem 0;
    }
    .videos-container{
        margin: 0;
    }
    .info{
        padding: 4rem 2rem;
    }
    .testimonial{
        margin-bottom: 0;
    }
    .team-container{
        .team-member-container{
            width: 100%;
            margin: 2rem;
        }
    }
}
</style>

<style lang="scss">

.vueperslides__arrows{
    color: #fff !important;
}
</style>